export const jsonData = {
  code: 200,
  message: 'OK',
  data: {
    calendar_events: [
      {
        title: 'Movie Night',
        description: 'Screening of classic films',
        location: 'Home Theater',
        startTime: '2023-09-17 07:45',
        endTime: '2023-09-17 10:00',
      },
      {
        title: 'Shopping Trip',
        description: 'Finding back-to-school supplies',
        location: 'Mall',
        startTime: '2023-09-15 23:30',
        endTime: '2023-09-16 01:00',
      },
      {
        title: 'Book Club Discussion',
        description: 'Analyzing the latest novel',
        location: 'Coffee Haven',
        startTime: '2023-09-15 06:00',
        endTime: '2023-09-15 07:30',
      },
      {
        title: 'Family Gathering',
        description: "Celebrating Grandma's birthday",
        location: "Grandparents' House",
        startTime: '2023-09-14 01:59',
        endTime: '2023-09-14 04:59',
      },
      {
        title: 'Concert',
        description: 'Live music performance',
        location: 'City Stadium',
        startTime: '2023-09-13 08:30',
        endTime: '2023-09-13 10:59',
      },
      {
        title: 'Project Deadline',
        description: 'Final submission due',
        location: 'Home Office',
        startTime: '2023-09-12 03:00',
        endTime: '2023-09-12 11:59',
      },
      {
        title: 'Dinner with Friends',
        description: 'Catching up over a meal',
        location: 'The Bistro',
        startTime: '2023-09-11 07:00',
        endTime: '2023-09-11 09:00',
      },
      {
        title: "Doctor's Appointment",
        description: 'Annual checkup',
        location: 'Health Clinic',
        startTime: '2023-09-09 23:15',
        endTime: '2023-09-10 00:00',
      },
      {
        title: 'Yoga Class',
        description: 'Relaxation and flexibility',
        location: 'Lotus Yoga Studio',
        startTime: '2023-09-09 05:30',
        endTime: '2023-09-09 06:45',
      },
      {
        title: 'Team Meeting',
        description: 'Discuss project updates',
        location: 'Conference Room A',
        startTime: '2023-09-07 21:00',
        endTime: '2023-09-07 22:30',
      },
    ],
    update_time: 1697596039,
    total: 10,
  },
};
